import { DEFAULT_ERROR_TEXT } from '../../../constants';
import api from '../base/axios';

export async function getMasterStats() {
    try {
        const res = await api.get('get-order-stats');
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function getCashierStats() {
    try {
        const res = await api.get('get-order-stats-cashier');
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function getProfile() {
    try {
        const res = await api.get('user');
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}
