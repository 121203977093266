import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import styled, { isStyledComponent } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import { Table } from '@/shared/components/TableElements';
import {
  cancelOrder, completeOrder, getOrderPreview, getTireWorkersList, getWashWorkersList, setWorker, startWork,
} from '../../../utils/api/task';
import TasksTable from './components/TasksTable';
import Modal from '../../../shared/components/Modal';
import { getMasterStats } from '../../../utils/api/profile';
import { tasksStats } from '../../../redux/actions/tasksAction';
import StepThree from '../NewTask/components/steps/StepThree';
import PhoneField from './components/PhoneField';
import Badge from '../../../shared/components/Badge';
import Worker from './components/Worker';

export default function HandleTask() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [washerList, setWasherList] = useState([]);
  const [tireWorkersList, setTireWorkersList] = useState([]);
  const history = useHistory();
  const [allSelectedServices, setAllSelectedServices] = useState([]);
  const [selectedMasters, setSelectedMasters] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      getOrderPreview(id).then(res => setOrder(res.data));
      getWashWorkersList().then(res => setWasherList(res.data));
      getTireWorkersList().then(res => setTireWorkersList(res.data));
    }
  }, []);

  function getPayClass(data) {
    let newPayClass = 'warning';
    if (data.is_legal_client) {
      newPayClass = 'primary';
    } else if (data.pay_status === 3) {
      newPayClass = 'success';
    } else if (data.pay_status === 4) {
      newPayClass = 'success';
    }
    return newPayClass;
  }

  function getJobClass(data) {
    let newJobClass = 'warning';
    if (data.job_status === 'in_progress') {
      newJobClass = 'primary';
    } else if (data.job_status === 'done') {
      newJobClass = 'success';
    } else if (data.job_status === 'canceled') {
      newJobClass = 'outline-secondary';
    }
    return newJobClass;
  }


  return (
    <>
      {!isMobile && (
        <BackButtonWrapper>

          <Col xs={5}>
            <Button onClick={history.goBack} variant="outline-secondary"><ArrowLeftIcon /><span>В работу</span></Button>
          </Col>
        </BackButtonWrapper>
      )}



      {order && (
        <div>

          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                {isMobile && (
                  <BackButtonWrapper>

                    <Col xs={10}>
                      <Button onClick={history.goBack} variant="outline-secondary"><ArrowLeftIcon /><span>В работу</span></Button>
                    </Col>
                  </BackButtonWrapper>
                )}

                <CardTitle>
                  <Header>
                    <div>
                      <h3>
                        # {order.order_id}{' '}
                        <Badge bg="primary">
                          {order.car.plate_number}
                        </Badge>
                      </h3>
                      <div>
                        {order.car.model_text}
                      </div>
                      <div>
                        <Badge bg={getJobClass(order)}>{order.job_status_text}</Badge>
                        <Badge bg={getPayClass(order)}>{order.pay_status_text}</Badge>
                      </div>
                    </div>

                  </Header>

                </CardTitle>
                <CardSubhead>
                  <PhoneField
                    phone={order.client.phone}
                    orderId={order.order_id}
                    onSuccess={() => {
                      getOrderPreview(id).then(res => setOrder(res.data));
                    }}
                    disableEdit={order.job_status === 'done' || order.job_status === 'canceled'}
                  />

                  <div>Ключи: {order.car.keys}</div>
                  <div>Дата: {order.datetime}</div>
                  {order.master_name && (
                    <div>Мастер: {order.master_name}</div>
                  )}
                </CardSubhead>



              </CardBody>
            </Card>
          </Col>
          <Card>
            <CardBody>
              {order.items?.map(service => (
                <ServiceItemWrapper>
                  <ServiceName>{service.name} -  x {service.quantity}</ServiceName>  <div>{service.sum}₽</div>

                </ServiceItemWrapper>

              ))}
              <Price>Без скидок: {order.total_sum}₽</Price>
              <Price>С учётом скидок: {order.pay_sum}₽</Price>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h5>Назначить исполнителей</h5>

              <Modal
                color="primary"
                title="Назначить исполнителей"
                btn="primary"
                btnComponent={toggle => (
                  <Button
                    variant="primary"
                    onClick={() => { toggle(); }}
                    size="sm"
                  >Назначить
                  </Button>
                )}
                onClose={() => setSelectedMasters([])}
                component={() => (
                  <div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {washerList.map((washer, washerIndex) => (
                          <Worker
                            worker={washer}
                            color={washer.color_font}
                            backgroundColor={washer.color_back}
                            index={washerIndex}
                            defaultValue={!!selectedMasters.find(master => master.id === washer.worker_id)}
                            onSelect={(selectedItem) => {
                              if (!selectedMasters.includes(selectedItem)) {
                                selectedMasters.push({ id: selectedItem.worker_id, name: selectedItem.name });
                                setSelectedMasters([...selectedMasters]);
                              }
                            }}
                            onRemove={(selectedItem) => {
                              const itemIndex = selectedMasters.map(w => w.id).indexOf(selectedItem.worker_id);
                              if (itemIndex > -1) {
                                selectedMasters.splice(itemIndex, 1);
                                if (selectedMasters?.length) {
                                  setSelectedMasters([...selectedMasters]);
                                } else {
                                  setSelectedMasters([]);
                                }
                              }
                            }}
                          />
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
                message="Вы уверены, что хотите завершить заказ ?"
                onSuccess={async () => {
                  await setWorker({ order_id: order.order_id, workers: selectedMasters.map(w => w.id || w.worker_id) }).then((res) => {
                    getMasterStats().then((response) => {
                      dispatch(tasksStats(response.data));
                    });
                    setSelectedMasters([]);
                    setTimeout(async () => {
                      const newOrder = await getOrderPreview(id);
                      setOrder(newOrder.data);
                    }, 500);
                  });
                }}


              />
            </CardBody>
          </Card>
          <TasksTable
            services={order.items}
            washerList={washerList}
            tierList={tireWorkersList}
            onWorkersChange={async () => {
              setTimeout(async () => {
                const newOrder = await getOrderPreview(id);
                setOrder(newOrder.data);
              }, 500);
            }}
            workJobStatus={order.job_status}
            disableChange={!order.can_be_changed}
          />
          {(order.job_status === 'waiting' || order.job_status === 'in_progress') && (
            <Card>
              <CardBody>
                <FormButtonToolbar>
                  <Modal
                    color="primary"
                    title="Изменить услуги"
                    btn="primary"
                    hideBottomButtons
                    btnComponent={toggle => (
                      <Button
                        variant="primary"
                        onClick={() => { toggle(); }}
                        size="sm"
                      >Изменить услуги
                      </Button>
                    )}
                    component={onSuccess => (
                      <StepThree
                        onSubmit={onSuccess}
                        classId={order.car.class_id}
                        clientId={order.client.id}
                        orderId={order.order_id}
                        allSelectedServices={allSelectedServices}
                        setAllSelectedServices={setAllSelectedServices}
                        editMode
                        plateNumber={order.car.plate_number}
                      />
                    )}
                    message={`Вы уверены, что хотите завершить заказ ${order.order_id}?`}
                    onSuccess={async () => {
                      setTimeout(async () => {
                        const res = await getOrderPreview(id);
                        setOrder(res.data);
                      }, 1000);
                    }}

                  />

                  {order.job_status === 'in_progress' && (
                    <>
                      <Modal
                        color="primary"
                        title=""
                        btn="primary"
                        btnComponent={toggle => (
                          <Button
                            variant="primary"
                            onClick={() => toggle()}
                            size="sm"
                          >Завершить
                          </Button>
                        )}
                        message={`Вы уверены, что хотите завершить заказ ${order.order_id}?`}
                        onSuccess={() => completeOrder(order.order_id).then(() => {
                          getMasterStats().then((response) => {
                            dispatch(tasksStats(response.data));

                            history.replace('/master/tasks/pending');
                          });
                        })}
                      />

                    </>

                  )}
                  {order.is_can_be_started && (
                    <Modal
                      color="success"
                      title=""
                      btn="success"
                      btnComponent={toggle => (
                        <Button
                          variant="success"
                          onClick={() => toggle()}
                          size="sm"
                        >В работу
                        </Button>
                      )}
                      message={`Вы уверены, что хотите начать работу над заказом ${order.order_id}?`}
                      onSuccess={() => startWork(order.order_id).then(() => {
                        getMasterStats().then((response) => {
                          dispatch(tasksStats(response.data));
                          history.replace('/master/tasks/pending');
                        });
                      })}
                    />
                  )}
                  {order.job_status !== 'done' && order.job_status !== 'canceled' && (
                    <Modal
                      color="danger"
                      title=""
                      btn="Danger"
                      btnComponent={toggle => (
                        <Button
                          variant="danger"
                          onClick={() => toggle()}
                          size="sm"
                        >Отменить заказ
                        </Button>
                      )}
                      message={`Вы уверены, что хотите ОТМЕНИТЬ заказ ${order.order_id}?`}
                      onSuccess={() => {
                        cancelOrder(order.order_id);
                        setTimeout(() => {
                          getOrderPreview(id).then(res => setOrder(res.data));
                        }, 1000);
                      }}
                    />
                  )}

                </FormButtonToolbar>
              </CardBody>
            </Card>
          )}
        </div>
      )}

    </>
  );
}


const Header = styled.div`
  display:flex;
  justify-content:space-between;
`;

const BackButtonWrapper = styled.div`
  /* padding-left: 20px; */
`;

const ServiceItemWrapper = styled('li')`
  margin-top:3px;
  display:flex;
  flex-wrap:no-wrap;
  align-items:center;
  padding-right:30px;
  gap:30px;
  margin-bottom: 10px;
`;

const Price = styled.div`
  font-weight: 700;
  margin-top: 5px;
`;


const ServiceName = styled.div`
color: ${({ color }) => color};
background-color: ${({ backgroundColor }) => backgroundColor};
border-radius: 8px;
`;
