import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Col } from 'react-bootstrap';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { WizardFormContainer, WizardTitle } from '@/shared/components/form/WizardFormElements';
import { showNotification } from '@/shared/components/Notification';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StepPropTypes, StepDefaultProps } from './Props';
import { getOrderPreview, keysLeft, keysTaken } from '../../../../../utils/api/task';
import PreviewTable from '../PreviewTable';

const StepFour = ({ orderId }) => {
  const [services, setServices] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const { theme, rtl } = useSelector(state => state);
  const history = useHistory();
  useEffect(() => {
    getOrderPreview(orderId).then(res => setServices(res.data));
  }, []);


  return (
    <Form horizontal>
      {services && <PreviewTable services={services} orderId={orderId} />}
      {!showButtons && (
        <FormButtonToolbar>
          <OptionWrapper>
            <Button
              variant="success"
              onClick={() => keysTaken(orderId).then((res) => {
                setShowButtons(true);
                // showNotification(
                //   theme,
                //   rtl,
                //   'Заказ принят',
                //   res.details,
                //   'success',
                // );
              })}
            >Ключи получены
            </Button>
          </OptionWrapper>
          <OptionWrapper>
            <Button
              onClick={() => keysLeft(orderId).then((res) => {
                  setShowButtons(true);
                  // showNotification(
                  //     theme,
                  //     rtl,
                  //     'Success',
                  //     res.data,
                  //     'success',
                  // );
                })}
              variant="outline-secondary"
            >Ключи у клиента
            </Button>
          </OptionWrapper>
        </FormButtonToolbar>
      )}
      <Col xs={12}>
        {showButtons && (
          <ButtonsContainer>
            <Button
              variant="success"
              onClick={() => history.replace(`/master/task/${orderId}`, { orderId })}
            >Назначить исполнителей
            </Button>
            <Button
              variant="primary"
              onClick={() => window.location.reload()}
            >К новому заказу
            </Button>
          </ButtonsContainer>
        )}
      </Col>
    </Form>
  );
};

StepFour.propTypes = StepPropTypes;
StepFour.defaultProps = StepDefaultProps;

export default StepFour;



const OptionWrapper = styled('div')`
  display:flex;
  margin-top:10px;
  align-items:center
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px
`;

const Form = styled(WizardFormContainer)`
  padding: 0;
`;
