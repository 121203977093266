import { Checkbox, IconButton, Input } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RemoveIcon from 'mdi-react/MinusIcon';
import AddIcon from 'mdi-react/PlusIcon';
import { Button } from '@/shared/components/Button';
import { updateOrder } from '../../../../../utils/api/task';

export default function TireServiceItem({
  service, orderId, phone, selectedProfileId, selectedRadiusId, setServiceValues,
}) {
  const [quantity, setQuantity] = useState(1);
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    setServiceValues({
      id: service.service_id, isWashService: false, quantity, status: isSelected, totalPrice: service.price * quantity,
    });
  }, [isSelected, quantity]);



  return (
    <ServiceItemWrapper>
      <ServiceName backgroundColor={service.color_back} color={service.color_font}>{service.name} - {service.price}₽</ServiceName>
      {isSelected && (
        <>
          <BtnWrapper>
            <Button
              variant="danger"
              onClick={() => {
                setQuantity(quantity - 1);
                if (quantity === 1) {
                  setIsSelected(false);
                  setQuantity(1);
                }
              }}
            >
              <RemoveIcon />
            </Button>
            <QuantityInput
              InputProps={{ inputProps: { min: 0 } }}
              defaultValue={1}
              type="number"
              value={quantity}
              onChange={e => setQuantity(e.target.value)}
            />
            <Button
              variant="primary"
              style={{ marginLeft: 10 }}
              onClick={() => {
                setQuantity(quantity + 1);
              }}
            >
              <AddIcon />
            </Button>
          </BtnWrapper>
          <Price>{service.price * quantity}₽</Price>
        </>
      )}
      {!isSelected && (
        <Button
          size="sm"
          variant="primary"
          onClick={() => {
            setIsSelected(!isSelected);
          }}
        >+
        </Button>
      )}
    </ServiceItemWrapper>
  );
}


const ServiceItemWrapper = styled('li')`
  margin-top:3px;
  display:flex;
  flex-wrap:no-wrap;
  align-items:center;
  justify-content:space-between;
  gap:10px

`;
const ServiceName = styled('div')`
  width: fit-content;
  color: ${({ color }) => color};
background-color: ${({ backgroundColor }) => backgroundColor};
border-radius: 8px;
margin-right: 20px;
text-align: center;
padding: 5px;

`;

const QuantityInput = styled(Input)`
  width: 30px;
  height:30px
  margin-right:20px
`;

const BtnWrapper = styled.div`
display:flex;
align-items:center;
button{
  margin-top:0;
  margin-bottom:0;
  padding: 5px 10px;
}
`;


const Price = styled.div`
display:flex;
align-items:center;

`;
