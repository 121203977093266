import React, { useEffect, useState } from 'react';
import { Button } from '@/shared/components/Button';
import MaskedInput from 'react-text-mask';
import {
  IconButton,
} from '@material-ui/core';
import EditIcon from 'mdi-react/EditIcon';
import PlusIcon from 'mdi-react/PlusBoldIcon';
import SaveIcon from 'mdi-react/CheckCircleOutlineIcon';
import CancelIcon from 'mdi-react/CancelBoldIcon';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { updateOrder } from '../../../../utils/api/task';

export default function PhoneField({
  phone, orderId, onSuccess, disableEdit,
}) {
  const [isSaveMode, setIsSaveMode] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
  } = useForm({ shouldFocusError: false });
  const onSave = async (data) => {
    await updateOrder({
      orderId,
      phone: data.phone_number,
    });
    setIsSaveMode(false);
    onSuccess();
  };

  useEffect(() => {
    setValue('phone_number', phone);
  }, []);


  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <div>Номер клиента: {!isSaveMode ? phone : (
        <Controller
          name="phone_number"
          control={control}
          render={({ field }) => (
            <MaskedInput
              mask={['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              name="carNumber"
              type="text"
              placeholder="+7 (999) 999-99-99"
              minLength={16}
              guide={false}
              defaultValue={phone}
              {...field}
            />
          )}
          rules={{ required: false, minLength: 12 }}
          defaultValue=""
        />

      )}
        {
          // eslint-disable-next-line no-nested-ternary
          isSaveMode ? (
            <>
              <IconButton type="submit"><SaveIcon />
              </IconButton>
              <IconButton onClick={() => {
                setIsSaveMode(!isSaveMode);
              }}
              ><CancelIcon />
              </IconButton>
            </>
          )
            : !disableEdit
              ? (
                <IconButton onClick={() => {
                setIsSaveMode(!isSaveMode);
              }}
                >
                  {phone ? <EditIcon /> : <PlusIcon />}
                </IconButton>
) : null


        }

      </div>
    </Form>
  );
}


const Form = styled.form`
    padding: 0;
    margin: 0;
    button{
        padding: 0 !important;
        margin-left: 10px;
    }
`;
