import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import { SelectField } from '@/shared/components/form/Select';
import {
  colorAccent, colorRed,
} from '@/utils/palette';
import { CheckBoxField } from '../../../../../shared/components/form/CheckBox';
import { getSuggestPlate } from '../../../../../utils/api/task';

const StepOne = ({
  onSubmit, hideSubmitBtn, defaultValue, setIsValidCarNumber,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty },
    setValue,

  } = useForm();
  const [enableMask, setEnableMask] = useState(true);
  const [suggestPlates, setSuggestPlates] = useState([]);
  const [showSuggests, setShowSuggests] = useState(false);
  useEffect(() => {
    setIsValidCarNumber(isValid);
  }, [isValid]);


  // eslint-disable-next-line consistent-return
  async function getSuggestPlates(text) {
    const res = await getSuggestPlate(text);
    if (res?.length) {
      setSuggestPlates(res?.map((item, index) => ({ ...item, value: index, label: item })));
      setShowSuggests(true);
      return res.data;
    }
    return setShowSuggests(false);
  }
  function cellCarMask(value) {
    const numbers = value.split('');
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    const allowedChars = /[А,а,В,в,Е,е,К,к,М,м,Н,н,О,о,Р,р,С,с,Т,т,У,у,Х,х\s]/;

    if (numberLength > 8) {
      return [allowedChars, /\d/, /\d/, /\d/, allowedChars, allowedChars, /\d/, /\d/, /\d/];
    }
    return [allowedChars, /\d/, /\d/, /\d/, allowedChars, allowedChars, /\d/, /\d/];
  }



  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <FormContainer onSubmit={handleSubmit(onSubmit, e => console.lg(e))}>
            <FormGroup>
              <FormGroupLabel>Номер ТС</FormGroupLabel>
              <FormGroupField>
                <Controller
                  name="carNumber"
                  control={control}
                  rules={{ required: true, minLength: enableMask ? 8 : 1 }}
                  render={({ field }) => (
                    <InputWrapper>
                      <CustomMaskedInput
                        mask={enableMask ? cellCarMask : value => value.split('').map(() => /./)}
                        name="carNumber"
                        type="text"
                        placeholder="Х999ХХ999"
                        guide={false}
                        {...field}
                        disabled={!hideSubmitBtn}
                        onChange={(e) => {
                            field.onChange(enableMask ? e.target.value.toUpperCase() : e.target.value);
                            if (e.target.value) {
                              getSuggestPlates(e.target.value);
                            }
                          }}
                        onBlur={(e) => {
                            field.onBlur(e);
                            setTimeout(() => {
                              setShowSuggests(false);
                            }, 100);
                          }}
                        autocomplete="off"
                        error={!isValid && isDirty && enableMask}
                      />
                      {!!suggestPlates?.length && showSuggests && (
                      <SuggestContainer>
                        {suggestPlates.map(item => (
                          <Suggestion onClick={() => {
                                field.onChange(item?.label || item);
                                setShowSuggests(false);
                              }}
                          >{item?.label || item}
                          </Suggestion>
                            ))}
                      </SuggestContainer>
                        )}
                    </InputWrapper>

                    )}
                  defaultValue={defaultValue}
                />



              </FormGroupField>

              <CheckboxWrapper>
                <CheckBoxField disabled={!hideSubmitBtn} label="Номер нестандартный" checked={!enableMask} onChange={() => setEnableMask(!enableMask)} />
              </CheckboxWrapper>
            </FormGroup>

            <FormButtonToolbar>
              <Button
                variant="primary"
                type="submit"
                disabled={!isValid}
              >Принять заказ
              </Button>
            </FormButtonToolbar>
          </FormContainer>
        </CardBody>
      </Card>
    </Col>
  );
};

StepOne.propTypes = {
  onSubmit: PropTypes.func,
};

StepOne.defaultProps = {
  onSubmit: () => { },
};

export default StepOne;


const CheckboxWrapper = styled.div`
 
  margin-top:10px
`;


const CustomMaskedInput = styled(MaskedInput)`
:active{

  border-color: ${({ error }) => (error ? colorRed : colorAccent)} !important;
}
:focus{

border-color: ${({ error }) => (error ? colorRed : colorAccent)} !important;
}
`;

const InputWrapper = styled.div`
display: flex;
flex-direction: column;
flex:1
`;

const SuggestContainer = styled.div`
border: 1px solid #f2f4f7;
background-color: white;  
padding-top: 0px 15px;
`;

const Suggestion = styled.div`
:hover{
cursor: pointer;
background: #fafbfe;
}
padding-left: 8px;
margin-top: 10px;
`;
