export default {
  client_id: '',
  project_id: '',
  auth_uri: '',
  token_uri: '',
  auth_provider_x509_cert_url: '',
  client_secret: '',
  redirect_uris: [
    '',
    '',
    '',
  ],
  javascript_origins: [
    '',
    '',
    '',
  ],
};
