import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import RegisterForm from '@/shared/components/account/login_register/LoginRegisterForm';
import { useDispatch } from 'react-redux';
import {
  AccountCard,
  AccountContent,
  AccountHaveAccount,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import { signUp } from '../../../utils/api/auth';
import { auth } from '../../../redux/actions/authActions';

const Register = ({ history }) => {
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  
  const handleSubmit = async (user) => {
    const res = await signUp(user);
    if (res.result === 'ok') {
      localStorage.setItem('token', res.data.token);
      dispatch(auth({ name: res.data.name, role: res.data.role }));
      history.push('/');
    } else {
      setError(res.details);
    }
  };

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>Welcome to
              <AccountLogo> Easy
                <AccountLogoAccent>DEV</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            <h4 className="subhead">Create an account</h4>
          </AccountHead>
          <RegisterForm onSubmit={handleSubmit} errorMessage={error} />
          <AccountHaveAccount>
            <p>Already have an account? <NavLink to="/log_in">Login</NavLink></p>
          </AccountHaveAccount>
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
};

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(Register);
