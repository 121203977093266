import axios from 'axios';
import { showNotification } from '@/shared/components/Notification';
// eslint-disable-next-line import/no-cycle
import { logout } from '@/shared/components/account/auth/withAuth0';
import { getToken } from '../../helpers';


const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    // console.log('request config', config);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
  ,
);

api.interceptors.response.use(
  (value) => {
    if (value.data.details) {
      showNotification(
        { className: 'light' },
        { direction: 'ltr' },
        'Success',
        value.data.details,
        'success',
      );
    }
    // console.log(value.data.details);
    return value;
  },
  (error) => {
    if (error.response.status === 401 && localStorage.getItem('token')) {
      localStorage.removeItem('token');
      logout();
    }
    if (error.response.data.details) {
      showNotification(
        { className: 'light' },
        { direction: 'ltr' },
        'Error',
        error.response.data.details,
        'danger',
      );
    }
    Promise.reject(error);
  }
  ,
);

export default api;
