

export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH';



export function auth({ name, role }) {
  return {
    type: AUTHENTICATE,
    user: { name, role },
  };
}

export function logOut({ name, role }) {
  return {
    type: LOGOUT,
    user: { name, role },
  };
}

export function authError(error) {
  return {
    type: AUTHENTICATE_ERROR_AUTH,
    error,
  };
}
