import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Card } from '@/shared/components/Card';
import {
  WizardFormWrap, WizardStep, WizardSteps, WizardWrap,
} from '@/shared/components/form/WizardFormElements';
import { useSelector } from 'react-redux';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepFour from './StepFour';
import CreateTask from '../../../../../containers/App/Forms/CreateTask';
import { createNewTask, getCarInfoByPlate } from '../../../../../utils/api/task';

const CreateWizardForm = ({ onSubmit }) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState({});
  const [thirdPageCompleted, setThirdPageCompleted] = useState(false);
  const [carNumber, setCarNumber] = useState('');
  const [classId, setClassId] = useState('');
  const [defaultValues, setDefaultValues] = useState('');
  const [clientId, setClientId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [phone, setPhone] = useState('');
  const [allSelectedServices, setAllSelectedServices] = useState([]);
  const [isValidCarNumber, setIsValidCarNumber] = useState(true);
  const [loyaltyDiscount, setloyaltyDiscount] = useState(0);
  const nextPage = (newData) => {
    setData(newData);
    setPage(page + 1);
  };
  const user = useSelector(state => state.profile);
  const previousPage = () => {
    setPage(page - 1);
  };

  const submitHandler = (newData) => {
    setData(newData);
    onSubmit(newData);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <WizardWrap>
            <WizardSteps>
              <WizardStep
                active={page === 1 || page === 2}
                onClick={() => {
                  if (carNumber) {
                    setPage(2);
                  }
                }}
              ><p>Шаг 1</p>
              </WizardStep>
              <WizardStep
                active={page === 3}
                onClick={() => {
                  if (orderId) {
                    setPage(3);
                  }
                }}
              ><p>Шаг 2</p>
              </WizardStep>
              <WizardStep
                active={page === 4}
                onClick={() => {
                  if (thirdPageCompleted) {
                    setPage(4);
                  }
                }}
              ><p>Шаг 3</p>
              </WizardStep>
            </WizardSteps>
            <div>
              {(page === 1 || page === 2) && (
                <Col>
                  <StepOne
                    onSubmit={async (e) => {
                      const res = await getCarInfoByPlate({ plate: e });
                      setCarNumber(e.carNumber);
                      if (res?.data?.is_new === false) {
                        setDefaultValues({ ...res.data, phone_number: res.data.client_phone, name: res.data.client_name });
                        if (res?.data?.loyalty_discount) {
                          setloyaltyDiscount(res?.data?.loyalty_discount);
                        }
                      } else {
                        setDefaultValues({});
                      }
                      if (page === 1) {
                        nextPage();
                      }
                    }}
                    setIsValidCarNumber={value => setIsValidCarNumber(value)}
                    hideSubmitBtn
                    defaultValue={carNumber}
                  />
                </Col>
              )}
              {page === 2 && (
                <Col xs={12}>
                  <CreateTask
                    onSubmit={async (taskData) => {
                      const res = await createNewTask({
                        plate_number: carNumber,
                        model_id: taskData.model_id?.model_id || taskData.model_id?.id,
                        class_id: taskData.class_id.value,
                        client_id: defaultValues?.client_id,
                        phone_number:
                          taskData.phone_number,
                        comment: taskData.comment,
                        name: taskData.name?.label,
                      });
                      setDefaultValues({
                        ...taskData,
                        brand_id: taskData.brand_id.id,
                        class_id: taskData.class_id.id,
                        model_id: taskData.model_id.id,
                      });
                      setClassId(taskData.class_id.id);
                      setClientId(res.data.client_id);
                      setOrderId(res.data.order_id);
                      setPhone(taskData.phone_number);
                      if (!res?.details) {
                        nextPage();
                      }
                    }}
                    carNumber={carNumber}
                    isValidCarNumber={isValidCarNumber}
                    defaultValues={defaultValues}
                  />
                </Col>
              )}
              {page === 3 && (
                <StepThree
                  previousPage={previousPage}
                  onSubmit={(values) => {
                    nextPage(values);
                    setThirdPageCompleted(true);
                  }}
                  loyaltyDiscount={loyaltyDiscount}
                  defaultValues={data}
                  classId={classId}
                  clientId={clientId}
                  orderId={orderId}
                  phone={phone}
                  allSelectedServices={allSelectedServices}
                  setAllSelectedServices={setAllSelectedServices}
                  plateNumber={carNumber}
                />
              )}
              {page === 4 && <StepFour orderId={orderId} />}
            </div>
          </WizardWrap>
        </Card>
      </Col>
    </Row>
  );
};

CreateWizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CreateWizardForm;
