import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { TableCheckbox, TableSortLabel } from '@/shared/components/MaterialTableElements';
import { isMobile } from 'react-device-detect';

const rows = [

];

if (!isMobile) {
  rows.push(
    {
      id: 'palette', disablePadding: true, label: '#',
    },
    {
      id: 'phone', disablePadding: false, label: 'Телефон',
    },
    {
      id: 'services', disablePadding: false, label: 'Услуги',
    },
    {
      id: 'keys', disablePadding: false, label: 'Ключи',
    },
  );
}

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const MatTableHead = ({
  onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
}) => {
  const rtl = useSelector(state => state.rtl);

  return (
    <TableHead>
      <TableRow>
        {rows.map(row => (
          <TableCell
            sort
            right
            key={row.id}
            align={rtl.direction === 'rtl' ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id, onRequestSort)}
              className="material-table__sort-label"
              dir="ltr"
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

MatTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default MatTableHead;
