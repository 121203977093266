import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function Worker({
  worker, index, defaultValue, onSelect, onRemove, color, backgroundColor,
}) {
  const [isSelected, setIsSelected] = useState(defaultValue);



  return (
    <Wrapper
      key={worker.worker_id}
      selected={isSelected}
      onClick={() => {
        setIsSelected(!isSelected);
        if (!isSelected) {
          onSelect(worker);
        } else {
          onRemove(worker);
        }
      }}
    >
      <td>{index + 1}</td>
      <WorkerName
        backgroundColor={backgroundColor}
        color={color}
      >{worker.name}
      </WorkerName>
    </Wrapper>
  );
}


const Wrapper = styled.tr`
border-right: ${({ selected }) => (selected ? '5px rgb(76, 225, 182) solid' : 'none')};

`;

const WorkerName = styled.div`
  background: ${({ backgroundColor }) => backgroundColor} !important;
  color: ${({ color }) => color} !important;
  max-width: 200px;
`;
