import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ActiveOrders from '../../../../pages/Cashier/ActiveOrders/ActiveOrders';
import PreviewTask from '../../../../pages/Cashier/PreviewTask/PreviewTask';

export default () => (
  <Switch>
    <Route path="/cashier/tasks/active" component={ActiveOrders} />
    <Route path="/cashier/task/:id" component={PreviewTask} />
  </Switch>
);
