import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { colorBackgroundBody } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import { useSelector } from 'react-redux';
import Layout from '../../../Layout/index';
import Master from './Master';
import Cashier from './Cashier';
import UI from './UI';

export default () => {
  const profile = useSelector(state => state.profile);
  return (
    <MainWrapper>
      <Layout />
      <ContainerWrap>
        {profile.role === 'master' ? <Route path="/master" component={Master} /> : <Route path="/cashier" component={Cashier} />}

        <Route path="/ui" component={UI} />


        {/* <Route path="/e_commerce_dashboard" component={Commerce} />
            <Route path="/online_marketing_dashboard" component={OnLineMarketingDashboard} />
            <Route path="/connect_wallet" component={ConnectWallet} />
            <Route exact path="/app_dashboard" component={AppDashboard} />
            <Route path="/booking_dashboard" component={BookingDashboard} />
            <Route exact path="/finance_dashboard" component={FinanceDashboard} />
            <Route path="/ui" component={UI} />
            <Route path="/mail" component={Mail} />
            <Route path="/chat" component={Chat} />
            <Route path="/todo" component={Todo} />
            <Route path="/forms" component={Forms} />
            <Route path="/tables" component={Tables} />
            <Route path="/charts" component={Charts} />
            <Route path="/maps" component={Maps} />
            <Route path="/account" component={Account} /> */}
        {/* <Route path="/e-commerce" component={ECommerce} /> */}
        {/* <Route path="/default_pages" component={DefaultPages} />
            <Route path="/documentation" component={Documentation} /> */}
      </ContainerWrap>
    </MainWrapper>
  );
};

// region STYLES

const ContainerWrap = styled.div`
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 576px) {
    /* padding-top: 150px; */
  }
`;

const MainWrapper = styled.div`
  overflow: hidden;
`;

// endregion
