import React from 'react';
import {
  Tabs as TabsWrapper, NavItem, NavLink, TabsWrap,
} from '@/shared/components/Tabs';
import {
  Card, Col, Nav, Tab,
} from 'react-bootstrap';
import OrdersTable from '../../../shared/components/OrdersTable';

export default function AllTasks() {
  return (
    <Col md={12} lg={12}>
      <Card>
        <TabsWrapper>
          <Tab.Container defaultActiveKey="1">
            <Nav className="nav-tabs">
              <NavItem>
                <NavLink eventKey="1">
                  Мойка
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink eventKey="2">
                  Шиномонтаж
                </NavLink>
              </NavItem> */}
            </Nav>
            <Tab.Content className="typography-message orders-table" ke>
              <Tab.Pane eventKey="1">
                <div><OrdersTable type="wash" status="all" /></div>

              </Tab.Pane>
              {/* <Tab.Pane eventKey="2">
                <div><OrdersTable type="tire" status="waiting" /></div>

              </Tab.Pane> */}

            </Tab.Content>
            
          </Tab.Container>
        </TabsWrapper>
      </Card>
    </Col>

  );
}
