import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NewTask from '../../../../pages/Master/NewTask/NewTask';
import HandleTask from '../../../../pages/Master/HandleTask/HandleTask';
import PendingTask from '../../../../pages/Master/PendingTasks';
import ActiveTasks from '../../../../pages/Master/ActiveTasks';
import AllTasks from '../../../../pages/Master/AllTasks';
import CompletedTasks from '../../../../pages/Master/CompletedTasks';

export default () => (
  <Switch>
    <Route path="/master/tasks/new" component={NewTask} />
    <Route path="/master/tasks/all" component={AllTasks} />
    <Route path="/master/tasks/completed" component={CompletedTasks} />
    <Route path="/master/tasks/pending" component={PendingTask} />
    <Route path="/master/tasks/active" component={ActiveTasks} />
    <Route path="/master/task/:id" exact component={HandleTask} />
    {/* <Route path="/master/task/pending" component={ReactVis} />
    <Route path="/master/task/active" component={Recharts} /> */}
  </Switch>
);
