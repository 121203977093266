import React, { useState } from 'react';
import {
  Table, TableCheckbox, TableRow, TableWrap, TablePagination, TableCell,
} from '@/shared/components/MaterialTableElements';
import { Checkbox } from '@material-ui/core';
import { Button } from '@/shared/components/Button';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import Badge from '@/shared/components/Badge';
import { isMobile } from 'react-device-detect';
import Collapse from '@/shared/components/Collapse';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import SettingsIcon from 'mdi-react/SettingsIcon';
import SendIcon from 'mdi-react/SendIcon';
import Panel from '@/shared/components/Panel';
import {
  colorAccent,
  colorBlue,
  colorRed,
  colorYellow,
  colorBackground,
  colorText,
  colorWhite,
  colorAdditional,
} from '@/utils/palette';
import { useDispatch } from 'react-redux';
import Modal from '../Modal';
import { cancelOrder, completeOrder } from '../../../utils/api/task';
import { getMasterStats } from '../../../utils/api/profile';
import { tasksStats } from '../../../redux/actions/tasksAction';

function ServiceListCell({ item, jobStatus }) {
  if (isMobile) {
    return null;
  }
  return (
    <TableCell right>
      <ServiceList type="1">
        {item.items.map(service => (
          <ServiceName backgroundColor={service.color_back}>
            <ServiceNameText backgroundColor={service.color_back} color={service.color_font}>{service.name} - {service.job_status_text}
            </ServiceNameText>
          </ServiceName>
        ))}
      </ServiceList>
    </TableCell>
  );
}

function KeysCell({ item }) {
  if (isMobile) {
    return null;
  }
  return (
    <TableCell right>
      {item.car.keys}
    </TableCell>
  );
}

function isShowingTime(date) {
  const currentTime = new Date().toLocaleDateString().split('/');
  const tempRussianTypeDate = currentTime.map(el => (el.length === 1 ? `0${el}` : el));
  return date === [tempRussianTypeDate[1], tempRussianTypeDate[0], tempRussianTypeDate[2]].join('.');
}

export default function SingleOrder({
  select, handleClick, item, updateList,
}) {
  const history = useHistory();
  const [jobStatus, setJobStatus] = useState(item.job_status);
  const dispatch = useDispatch();
  const labelColor = () => {
    switch (item.job_status) {
      case 'waiting':

        return colorRed;

      case 'in_progress':
        return colorBlue;

      case 'done':
        return colorAccent;
      default:
        return colorAdditional;
    }
  };

  return (
    <>

      {
        isMobile ? (
          <Panel
            xs={12}
            md={12}
            lg={12}
            divider
            time={`${item.datetime.split(' ')[0]} ${isShowingTime(item.datetime.split(' ').reverse()[0]) ? '' : item.datetime.split(' ').reverse()[0]}`}
            title={`${item.car.plate_number} / ${item.car.model_text}`}
            label={item.job_status_text}
            secondBackgroundColor={item.pay_status === 3 || item.pay_status === 4 ? colorAccent : colorRed}
            secondLabel={item.pay_status_text}
            backgroundColor={labelColor()}
            additionalLabelComponent={<CustomButton variant="outline-secondary" size="sm" onClick={() => { history.push(`/master/task/${item.order_id}`); }}><SettingsIcon size="30" /></CustomButton>}
            disablePanelButtons
          >

            <div>
              <ButtonsGroup>
                {item.job_status === 'in_progress' && (
                  <Modal
                    color="primary"
                    title=""
                    btn="primary"
                    btnComponent={toggle => (
                      <Button
                        variant="outline-primary"
                        onClick={() => toggle()}
                        size="sm"
                      >Завершить
                        <SendIcon />
                      </Button>
                    )}
                    message={`Вы уверены, что хотите завершить заказ ${item.order_id}?`}
                    onSuccess={() => completeOrder(item.order_id).then((res) => {
                      if (res.result === 'ok') {
                        getMasterStats().then((response) => {
                          dispatch(tasksStats(response.data));
                          setJobStatus(true);
                          updateList();
                        });
                      }
                    })}
                  />
                )}
                {item.job_status !== 'done' && item.job_status !== 'canceled' && (
                  <Modal
                    color="danger"
                    title=""
                    btn="Danger"
                    btnComponent={toggle => (
                      <Button
                        variant="outline-danger"
                        onClick={() => toggle()}
                        size="sm"
                      >Отменить заказ
                      </Button>
                    )}
                    message={`Вы уверены, что хотите ОТМЕНИТЬ заказ ${item.order_id}?`}
                    onSuccess={() => cancelOrder(item.order_id).then(updateList)}
                  />
                )}
              </ButtonsGroup>
            </div>
            <div>

              {item.items.map(service => <ServiceName backgroundColor={service.color_back} color={service.color_font}>{service.name} - {service.job_status_text}  -  {service.sum}₽</ServiceName>)}
            </div>
            <Price>Цена: {item?.sum_final || 0}₽</Price>

            <Collapse title="Дополнительная информация">
              <div>
                <h4>
                  <a href={`tel:${item.client.phone}`}>
                    {item.client.phone} {!!item.client.phone && !!item.client.name && '/'} {item.client.name || ''}
                  </a>
                </h4>
              </div>
              <div>
                <ServiceList type="1">
                  <div>
                    <CardTitle>
                      Заказ #{item.order_id} <Badge>{item.car.keys}</Badge>
                    </CardTitle>
                  </div>
                </ServiceList>
              </div>
            </Collapse>

          </Panel>
        ) : (
          <TableRow
            onClick={event => handleClick(event, item.id)}
            aria-checked={select}
            tabIndex={-1}
            key={item.id}
            selected={select}
          >

            <TableCell
              right
              component="th"
              scope="row"
              padding="none"
            >

              <div>
                <br />
                <CarNumber bg="success">
                  {item.car.plate_number}
                </CarNumber>
                &nbsp;&mdash;&nbsp;{item.datetime}
              </div>
              <div>
                <br />
                <Button variant="outline-secondary" size="sm" onClick={() => { history.push(`/master/task/${item.order_id}`); }}><SettingsIcon />  {item.can_be_changed ? 'Изменить' : 'Просмотр'}</Button>
                {item.job_status === 'in_progress' && (
                  <Modal
                    color="primary"
                    title=""
                    btn="primary"
                    btnComponent={toggle => (
                      <Button
                        variant="outline-primary"
                        onClick={() => toggle()}
                        size="sm"
                      >Завершить
                        <SendIcon />
                      </Button>
                    )}
                    message={`Вы уверены, что хотите завершить заказ ${item.order_id}?`}
                    onSuccess={() => completeOrder(item.order_id).then((res) => {
                      if (res.result === 'ok') {
                        setJobStatus(true);
                        updateList();
                      }
                    })}
                  />
                )}
              </div>


            </TableCell>
            <TableCell right>
              <a href={`tel:${item.client.phone}`}>

                {item.client.phone}
              </a>
            </TableCell>
            <ServiceListCell
              item={item}
              jobStatus={jobStatus}
            />
            <KeysCell
              item={item}
            />
          </TableRow>
        )
      }

    </>
  );
}

const ServiceNameText = styled.div`
padding-left: 10px;
padding-right: 10px;
  color: ${({ color }) => color};
background-color: ${({ backgroundColor }) => backgroundColor};
border-radius: 8px;
width: fit-content;
`;

const CustomButton = styled(Button)`
margin-left: 5px;
margin-top: 5px;
border: none !important;
svg{
  width: 30px;
  height: 30px;
}
`;

const ServiceName = styled('div')`
white-space: nowrap;

`;

const ServiceList = styled('div')`
list-style-image:none
`;


const CollapseContent = styled.div`
  padding-left: 30px;
`;

const CarNumber = styled(Badge)`
font-size:14px`;


const ButtonsGroup = styled.div`
  display: flex;
  gap:10px
`;

const Price = styled.div`
  font-weight: 700;
  margin-top: 5px;
`;
