import { Checkbox } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Button } from '@/shared/components/Button';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Table } from '@/shared/components/TableElements';
import Modal from '../../../../shared/components/Modal';
import { cancelService, changeServiceStatus, setWorker } from '../../../../utils/api/task';
import { getMasterStats } from '../../../../utils/api/profile';
import { tasksStats } from '../../../../redux/actions/tasksAction';
import Worker from './Worker';

export default function SingleService({
  item, washerList, tierList, orderId, index, onWorkersChange, disableChange = false, workJobStatus,
}) {
  const [selectedMasters, setSelectedMasters] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const disableEdit = state?.disableChange || disableChange;
  const [isDone, setIsDone] = useState(item.job_status === 'done');
  useEffect(() => {
    setSelectedMasters(item.workers);
  }, [item.workers]);


  return (
    <>
      <tr key={item.name}>
        <td>

          <ServiceName backgroundColor={item.color_back} color={item.color_font}>
            {index + 1}. {item.name} - {item.quantity} шт.


          </ServiceName>
          {!isCanceled && (
            <BtnsWrapper>

              {workJobStatus === 'in_progress' && (
                <Modal
                  color="danger"
                  title=""
                  btn="Danger"
                  btnComponent={toggle => <Button size="sm" variant="success" disabled={(disableEdit || (!isActive && selectedMasters?.length < 1))} onClick={() => toggle()}>{isDone ? 'Выполнено' : 'Выполнить'}</Button>}
                  message={isDone ? `Вы уверены, что хотите вернуть в работу услугу ${item.name}?` : `Вы уверены, что хотите завершить услугу ${item.name}?`}
                  onSuccess={() => { changeServiceStatus(item.order_item_id).then((res) => { if ((res?.result === 'ok')) setIsActive(!isActive); setIsDone(!isDone); }); }}
                />
              )}
            </BtnsWrapper>
          )}

        </td>
        <WorkersWrapper>
          <Workers>
            {!isCanceled && selectedMasters.map((washer, washerIndex) => (
              <WorkerName
                backgroundColor={washer.color_back}
                color={washer.color_font}
              >{washer.name} 
              </WorkerName>
            ))}
            {item.job_status !== 'done' && item.job_status !== 'canceled' && !isCanceled && (
              <Modal
                color="primary"
                title="Назначить исполнителей"
                btn="primary"
                onClose={() => { console.log('empty onclose'); }}
                btnComponent={toggle => (
                  <Button
                    variant="primary"
                    onClick={() => { toggle(); }}
                    size="sm"
                  >Назначить
                  </Button>
                )}
                component={() => (
                  <div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Имя</th>
                        </tr>
                      </thead>
                      <tbody>
                        {washerList.map((washer, washerIndex) => (
                          <Worker
                            worker={washer}
                            index={washerIndex}
                            color={washer.color_front}
                            backgroundColor={washer.color_back}
                            defaultValue={!!selectedMasters.find(master => master.id === washer.worker_id)}
                            onSelect={(selectedItem) => {
                              if (!selectedMasters.includes(selectedItem)) {
                                selectedMasters.push({ id: selectedItem.worker_id, name: selectedItem.name });
                                setSelectedMasters([...selectedMasters]);
                              }
                            }}
                            onRemove={(selectedItem) => {
                              const itemIndex = selectedMasters.map(w => w.id).indexOf(selectedItem.worker_id);
                              if (itemIndex > -1) {
                                selectedMasters.splice(itemIndex, 1);
                                if (selectedMasters?.length) {
                                  setSelectedMasters([...selectedMasters]);
                                } else {
                                  setSelectedMasters([]);
                                }
                              }
                            }}
                          />
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
                message="Вы уверены, что хотите завершить заказ ?"
                onSuccess={async () => {
                  await setWorker({ order_item_id: item.order_item_id, workers: selectedMasters.map(w => w.id || w.worker_id) }).then((res) => {
                    getMasterStats().then((response) => {
                      dispatch(tasksStats(response.data));
                    });
                    onWorkersChange();
                  });
                }}
              />
            )}

          </Workers>
          <BtnsWrapper>

            {item.job_status !== 'done' && item.job_status !== 'canceled' && !isCanceled && (
              <Modal
                color="danger"
                title=""
                btn="Danger"
                btnComponent={toggle => (
                  <Button
                    variant="outline-danger"
                    size="sm"
                    disabled={disableEdit}
                    onClick={() => toggle()}
                  >{isCanceled || item.job_status === 'canceled' ? 'Отменено' : 'Отменить'}
                  </Button>
                )}
                confirmButtonText="Да"
                cancelButtonText="Нет"
                message={isCanceled || item.job_status === 'canceled' ? `Вы уверены, что хотите снять отмену услуги ${item.name}?` : `Вы уверены, что хотите отменить услугу ${item.name}?`}
                onSuccess={() => cancelService(item.order_item_id).then((res) => { if ((res?.result === 'ok')) { setIsCanceled(!isCanceled); } })}
              />
            )}
          </BtnsWrapper>
        </WorkersWrapper>
      </tr>

    </>
  );
}


const BtnsWrapper = styled.div`
button{
  margin:0;
  margin-top:10px
}
`;

const ServiceName = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  width: fit-content;
`;



const Workers = styled.div`
  
  .searchWrapper{
  padding-left: 0px;
}
`;

const WorkerName = styled.div`
  background: ${({ backgroundColor }) => backgroundColor} !important;
  color: ${({ color }) => color};
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const WorkersWrapper = styled.td`
  
  padding-left: 0px !important;
`;
