
export const TASKS = 'TASKS';
export const TASKS_STATS = 'TASKS_STATS';



// eslint-disable-next-line camelcase
export function tasks({ waiting, in_progress }) {
  return {
    type: TASKS,
    tasks: { waiting, inWork: in_progress },
  };
}

export function tasksStats({
  // eslint-disable-next-line camelcase
 in_progress, waiting, waiting_for_payment, done, 
}) {
  return {
    type: TASKS_STATS,
    tasks: {
 in_progress, waiting, waitingForPayment: waiting_for_payment, done, 
},
  };
}

