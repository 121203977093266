import { PROFILE } from '../actions/profileActions';

const initialState = {
    email: '',
    emailVerifiedAt: '',
    id: '',
    name: '',
    role: '',
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE:
            return {
                email: action.user.email,
                emailVerifiedAt: action.user.emailVerifiedAt,
                id: action.user.id,
                name: action.user.name,
                role: action.user.role,
            };

        default:
            return state;
    }
};

export default profileReducer;

