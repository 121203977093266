const BasicTableData = () => {
  const header = [
    { id: 2, title: 'Время' },
    { id: 3, title: 'Услуга' },
    { id: 4, title: 'Сумма тогда / сейчас' },
  ];

  const headerResponsive = [
    { id: 1, title: '#' },
    { id: 2, title: 'Date' },
    { id: 3, title: 'Services' },
    { id: 4, title: 'общая сумма без учёта скидок' },
    { id: 5, title: 'Age' },
    { id: 7, title: 'Date' },
    { id: 8, title: 'Location' },
    { id: 9, title: 'общая сумма без учёта скидок сегодня' },
  ];

  const rows = [{
    id: 1,
    date: new Date().toString(),
    services: 'Morisson',
    oldPrice: '@dragon',
    currentPrice: 'success',

  }];

  const basicTableData = { tableHeaderData: header, tableHeaderResponsiveData: headerResponsive, tableRowsData: rows };
  return basicTableData;
};

export default BasicTableData;
