import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

export default function PrivateRoute({ children, ...rest }) {
    const user = useSelector(store => store.profile);
    if (!user.name) {
        return <Redirect to="/log_in" />;
    }
    return (
      <Route {...rest}>
        {children}
      </Route>
    );
}
