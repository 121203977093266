export const PROFILE = 'PROFILE';

export function profile({
    email,
    emailVerifiedAt,
    id,
    name,
    role,
}) {
    return {
        type: PROFILE,
        user: {
            email,
            emailVerifiedAt,
            id,
            name,
            role,
        },
    };
}


