import { TASKS, TASKS_STATS } from '../actions/tasksAction';


const initialState = {
    waiting: 0,
    inWork: 0,
    waitingForPayment: 0,
    done: 0,
};

const tasksReducer = (state = initialState, action) => {
    switch (action.type) {
        case TASKS:
            return { waiting: action.tasks.name, inWork: action.tasks.inWork };
        case TASKS_STATS:
            return {
 waiting: action.tasks.waiting, in_progress: action.tasks.in_progress, waitingForPayment: action.tasks.waitingForPayment, done: action.tasks.done, 
};

        default:
            return state;
    }
};

export default tasksReducer;

