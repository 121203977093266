import { Checkbox, IconButton, Input } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';
import RemoveIcon from 'mdi-react/MinusIcon';
import AddIcon from 'mdi-react/PlusIcon';
import { updateOrder } from '../../../../../utils/api/task';

export default function WashServiceItem({
  service, orderId, phone, setServiceValues, defaultValue = false,
  removeItem = () => { },
}) {
  console.log(defaultValue, 'defaultValue');
  const [quantity, setQuantity] = useState(defaultValue.quantity || service.quantity || 1);
  console.log(quantity, defaultValue.quantity, service.name);
  const [isSelected, setIsSelected] = useState(defaultValue);
  useEffect(() => {
    setServiceValues({
      id: service.service_id, isWashService: true, quantity, status: isSelected, totalPrice: service.price * quantity,
    });
  }, [isSelected, quantity]);

  useEffect(() => {
    if (defaultValue.quantity) {
      setQuantity(defaultValue.quantity);
    }
  }, [defaultValue]);


  useEffect(() => {
    if (defaultValue) {
      setIsSelected(!!defaultValue);
    }
  }, [defaultValue]);

  return (
    <ServiceItemWrapper collapsed={isSelected}>
      <ServiceName backgroundColor={service.color_back} color={service.color_font}>{service.name} - {service.price}₽</ServiceName>
      {isSelected && (
        <PriceWrapper>
          <BtnWrapper>
            <Button
              variant="danger"
              onClick={() => {
                setQuantity(quantity - 1);
                if (quantity === 1) {
                  setIsSelected(false);
                  setQuantity(1);
                  removeItem();
                }
              }}
            >
              <RemoveIcon />
            </Button>
            <QuantityInput
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
            />
            <Button
              variant="primary"
              style={{ marginLeft: 10 }}
              onClick={() => {
                setQuantity(quantity + 1);
              }}
            >
              <AddIcon />
            </Button>
          </BtnWrapper>
          <Price>{service.price * quantity}₽</Price>
        </PriceWrapper>
      )}

      {!isSelected && (
        <PlusBtnWrapper>

          <PlusBtn
            variant="primary"
            size="sm"
            onClick={() => {
              setIsSelected(!isSelected);
            }}
          >+
          </PlusBtn>
        </PlusBtnWrapper>
      )}
    </ServiceItemWrapper>
  );
}


const ServiceItemWrapper = styled('div')`
  margin-top:3px;
  display: flex;
  justify-content: space-between;
gap:10px;

`;

const PlusBtn = styled(Button)`
  width: 130px;
`;
const PlusBtnWrapper = styled.div`
width:190px
`;

const ServiceName = styled('div')`
  width: fit-content;
  color: ${({ color }) => color};
background-color: ${({ backgroundColor }) => backgroundColor};
border-radius: 8px;
margin-right: 20px;
text-align: center;
padding: 5px;
width:100px;

`;

const Price = styled.div`
display:flex;
align-items:center;
min-width: 50px;
`;


const PriceWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
const BtnWrapper = styled.div`
display:flex;
align-items:center;
width: 130px;
button{
  margin-top:0;
  margin-bottom:0;
  padding: 5px 10px;
}

`;

const QuantityInput = styled(Input)`
  width: 30px;
  min-width: 30px;
  height:30px
  margin-right:30px;
  input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}
`;

