import { DEFAULT_ERROR_TEXT } from '../../../constants';
import store from '../../../containers/App/store';
import { profile } from '../../../redux/actions/profileActions';
import api from '../base/axios';
import { getProfile } from '../profile';


export async function login({ username, password }) {
    try {
        const res = await api.post('login', {
            login: username,
            password,
        });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function signUp({ username, password, email }) {
    try {
        const res = await api.post('register', {
            name: username,
            password,
            password_repeat: password,
            email,
        });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function isAuth() {
    const res = await getProfile();
    return res;
}
