import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorBorder, colorBackground, colorHover } from '@/utils/palette';
import { left } from '@/utils/directions';
import { useSelector } from 'react-redux';
import SidebarLink, { SidebarLinkTitle } from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({
  onClick, collapse,
}) => {
  const {
    waiting,
    // eslint-disable-next-line camelcase
    in_progress,
    // eslint-disable-next-line camelcase
    waitingForPayment,
    done,
  } = useSelector(state => state.tasks);
  console.log(done);
  const profile = useSelector(state => state.profile);
  return (
    <SidebarContentWrap collapse={collapse}>
      <SidebarBlock collapse={false}>
        {profile.role === 'master' ? (
          <>
            <SidebarLink
              title="Новый заказ"
              icon="file-add"
              route="/master/tasks/new"
              onClick={onClick}
            />
            <SidebarLink
              title="Ожидают"
              icon="book"
              count={waiting?.all}
              route="/master/tasks/pending"
              onClick={onClick}
            />
            <SidebarLink
              title="В работе"
              icon="rocket"
              count={in_progress?.all}
              route="/master/tasks/active"
              onClick={onClick}
              badgeBackgroundColor="#70bbfd"
            />
            <SidebarLink
              title="Выполненные"
              icon="checkmark-circle"
              route="/master/tasks/completed"
              onClick={onClick}
              badgeBackgroundColor="#4ce1b6"
              count={done?.all}

            />
            <SidebarLink
              title="Все заказы"
              icon="list"
              route="/master/tasks/all"
              onClick={onClick}
            />
          </>
        ) : (
          <>
            <SidebarLink
              title="Заказы"
              icon="book"
              count={waitingForPayment}
              route="/cashier/tasks/active"
              onClick={onClick}
              fontSize={18}
              badgeFontSize={12}
            />
          </>
        )}


      </SidebarBlock>


    </SidebarContentWrap>
  );
};




SidebarContent.propTypes = {
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => { },
};

export default SidebarContent;

// region STYLES



const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;
  
  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
  
  @media screen and (min-width: 576px) {
    padding-top: 15px;
    
    ${props => props.collapse && `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;
  
  &:last-child {
    border: none;
  }
  
  @media screen and (min-width: 576px) {
    
    ${props => props.collapse && `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;

// endregion

