import { Checkbox } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import Multiselect from 'multiselect-react-dropdown';
import React, { useState } from 'react';
import { Button } from '@/shared/components/Button';
import styled from 'styled-components';
import Modal from '../../../../shared/components/Modal';
import { cancelService } from '../../../../utils/api/task';

const removeOrderItem = (itemId) => {
    cancelService(itemId).then(
        setTimeout(() => {
            window.location.reload();
        }, 500),
    );
};

export default function SingleService({ item, washerList, tierList }) {
  const [selectedMasters, setSelectedMasters] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  return (
    <tr key={item.name}>
      <TD>
        <div>{item.name}</div>
        {item.is_removable && (
          <ButtonDiv>
            <Modal
              color="primary"
              title=""
              btn="primary"
              btnComponent={toggle => (
                <Button
                  variant="primary"
                  onClick={toggle}
                  size="sm"
                >Отменить
                </Button>
              )}
              message="Вы уверены, что хотите отменить оплату за парковку после мойки сверх 2-х часов?"
              onSuccess={() => removeOrderItem(item.order_item_id)}
            />
          </ButtonDiv>
          )}
      </TD>
      <TD>{item.quantity}</TD>
      <TD>{item.job_status_text}</TD>
      <TD>{item.price_item}</TD>
      <TD>{item.sum_before_discount}</TD>
      <TD>{item.discount}</TD>
      <TD>{item.sum}</TD>
    </tr>
  );
}


const TD = styled.td`
  font-size: 15px;
`;

const ButtonDiv = styled.div`
  display: inline;
  margin-right: 10px;
`;
