import React from 'react';
import { Field, Form } from 'react-final-form';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormGroupIcon,
} from '@/shared/components/form/FormElements';

import { Button } from '@/shared/components/Button';
import { renderMaskedField } from '@/shared/components/form/FormField';
import { marginRight } from '@/utils/directions';
import { colorAdditional } from '@/utils/palette';

const MasterCard = `${process.env.PUBLIC_URL}/img/for_store/cards/mc.svg`;
const Cash = `${process.env.PUBLIC_URL}/img/for_store/cards/cash.svg`;
const Fuel = `${process.env.PUBLIC_URL}/img/for_store/cards/fuel.svg`;

const required = value => (value ? undefined : 'Required');

const PaymentForm = ({ onSubmit, isLoading, defaultValue }) => (
  <Form onSubmit={onSubmit} initialValues={{ method: false, sum: defaultValue }}>
    {({ handleSubmit, values }) => (
      <FormContainer horizontal onSubmit={handleSubmit}>
        <FormGroup>
          <FormGroupField>
            <PaymentMethod>
              <Field
                name="method"
                component={renderRadioButtonField}
                label={(
                  <div>
                    <PaymentMethodImage src={MasterCard} alt="card" />
                    <PaymentMethodName>Банковская карта</PaymentMethodName>
                  </div>
                )}
                radioValue="card"
              />
            </PaymentMethod>
            <PaymentMethod>
              <Field
                name="method"
                component={renderRadioButtonField}
                label={(
                  <div>
                    <PaymentMethodImage src={Cash} alt="cash" />
                    <PaymentMethodName>Наличные</PaymentMethodName>
                  </div>
                )}
                radioValue="cash"
              />
            </PaymentMethod>
            <PaymentMethod>
              <Field
                name="method"
                component={renderRadioButtonField}
                label={(
                  <div>
                    <PaymentMethodImage src={Fuel} alt="fuel_yandex" />
                    <PaymentMethodName>ТК: Яндекс</PaymentMethodName>
                  </div>
                )}
                radioValue="fuel_yandex"
              />
            </PaymentMethod>
            <PaymentMethod>
              <Field
                name="method"
                component={renderRadioButtonField}
                label={(
                  <div>
                    <PaymentMethodImage src={Fuel} alt="fuel_petrol" />
                    <PaymentMethodName>ТК: Петрол Плюс</PaymentMethodName>
                  </div>
                )}
                radioValue="fuel_petrol"
              />
            </PaymentMethod>
          </FormGroupField>
        </FormGroup>
        <FormGroup>
          <InfoBlock>Сумма платежа</InfoBlock>
          <FormGroupField>
            <PriceInput
              validate={required}
              name="sum"
              component="input"
              type="number"
              defaultValue={defaultValue}
            />
          </FormGroupField>
        </FormGroup>
        <FormButtonToolbar>
          <Button variant="primary" type="submit" size="sm" disabled={!values.sum || !values.method || isLoading}>Оплатить</Button>
        </FormButtonToolbar>
      </FormContainer>

    )}
  </Form>
);

PaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PaymentForm;

// region STYLES

const FormGroupDate = styled(FormGroup)`
  width: 100%;
  ${marginRight}: 20px;


  @media screen and (max-width: 767px) {
    ${marginRight}: 0;
  }
`;

const PriceInput = styled(Field)`
  border:1px solid #20c997 !important;
  font-size: 18px !important;
`;
const FormGroupCVC = styled(FormGroup)`
  max-width: 280px;
  width: 100%;


  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

const FormGroupDateCvc = styled.div`
  display: flex;
  width: 100%;


  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const InfoBlock = styled(FormGroupLabel)`
  font-size: 18px;
  margin-bottom: 10px;
`;


const PaymentMethod = styled.div`
  ${marginRight}: 80px;

  &:last-child {
    ${marginRight}: 0;
  }

  @media screen and (max-width: 500px){
    ${marginRight}: 0!important;
  };
`;

const PaymentMethodImage = styled.img`
  width: 45px;
  height: 40px;
`;

const PaymentMethodName = styled.p`
  font-size: 18px;
  padding-top: 10px;
  line-height: 13px;
  margin-top: 3px;
  color: ${colorAdditional};
  text-align: center;
  height: 0;
`;

const PaymentTotal = styled.h4`
  width: 100%;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 20px;
`;

// endregion
