import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '@/shared/components/Badge';
import { Button, Col, Row } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import Multiselect from 'multiselect-react-dropdown';
import BasicTableData from '../../../../containers/Tables/BasicTables/components/BasicTableData';
import { changeServiceStatus, setWorker, updateOrder } from '../../../../utils/api/task';
import SingleService from './SingleService';

const { tableHeaderData, tableRowsData } = BasicTableData();

const TasksTable = ({ services, washerList, tierList }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <Table bordered responsive>
          <thead>
            <tr>
              {[
                  { id: 1, title: 'Услуга' },
                  { id: 2, title: 'Количество' },
                  { id: 4, title: 'Статус' },
                  { id: 5, title: 'Цена без скидки' },
                  { id: 6, title: 'Сумма без скидки' },
                  { id: 7, title: 'Скидка' },
                  { id: 8, title: 'Сумма' },
                ].map(item => (
                  <TH key={item.id}>{item.title}</TH>
                ))}
            </tr>
          </thead>
          <tbody>
            {services.map((item => (
              <SingleService item={item} washerList={washerList} tierList={tierList} />

              )))}

          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
  );

export default TasksTable;


const ServiceItemWrapper = styled('li')`
  margin-top:3px;
  display:flex;
  flex-wrap:no-wrap;
  align-items:center;
  justify-content:space-between
`;

const TH = styled.th`
  font-size: 15px;
`;
