import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '@/shared/components/Badge';
import { Button, Col, Row } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import Panel from '@/shared/components/Panel';
import Collapse from '@/shared/components/Collapse';
import { isMobile } from 'react-device-detect';
import BasicTableData from '../../../../containers/Tables/BasicTables/components/BasicTableData';
import { updateOrder } from '../../../../utils/api/task';

const { tableHeaderData, tableRowsData } = BasicTableData();

const PreviewTable = ({ services, orderId, liteMode = false }) => {
  const [selectedService, setSelectedService] = useState(services.items);
  return (
    <>


      {isMobile ? (
        <Panel
          xs={12}
          md={12}
          lg={12}
          divider
          title={!liteMode && `${services.car.plate_number}`}
          // label={services.job_status}
          disablePanelButtons
          defaultCollapsed={false}
          subhead={!liteMode && (
            <div>
              <h4>
                <a href={`tel:${services.client.phone}`}>

                  {services.client.phone}
                </a>
              </h4>
              <div>{services.datetime}</div>
            </div>
          )}
        >
          <Collapse title="Дополнительная информация" defaultValue>
            <div>
              {selectedService?.map((service, index) => (
                <ServiceItemWrapper>
                  <ServiceName>{index + 1}. {service.name}</ServiceName>  <div>x {service.quantity} </div>
                  <ButtonWrapper>
                    <SumPrice>

                      {service.sum}₽
                    </SumPrice>
                    <Button
                      variant="minimal"
                      onClick={() => {
                        updateOrder({
                          action: 'delete',
                          serviceId: service.service_id,
                          isWashService: service.is_wash,
                          orderId,
                        }).then(() => {
                          setSelectedService(selectedService
                            .filter(current => !((current.service_id === service.service_id)
                              && (current.is_wash === service.is_wash))));
                        });
                      }}
                    >
                      { // eslint-disable-next-line jsx-a11y/img-redundant-alt
                      }                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Red_X.svg/1200px-Red_X.svg.png"
                        height="15px"
                        width="15px"
                        alt="image"
                      />
                    </Button>
                  </ButtonWrapper>
                </ServiceItemWrapper>
              ))}
              <Price>Без скидок: {services.total_sum}₽</Price>
              <Price>С учётом скидок: {services.pay_sum}₽</Price>
            </div>
          </Collapse>

        </Panel>
      ) : (
        <CardBody>

          <Table bordered responsive>
            <thead>
              <tr>
                {[
                  { id: 1, title: 'Заказ №' },
                  { id: 2, title: 'Дата' },
                  { id: 3, title: 'Номер ТС' },
                  { id: 4, title: 'Телефон' },
                  { id: 3, title: 'Услуги' },
                  { id: 4, title: 'Сумма без скидок' },
                ].map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr key={services.order_id}>
                <td>{services.order_id}</td>
                <td>{services.datetime}</td>
                <td>{services.car.plate_number}</td>
                <td>{services.client.phone}</td>
                <td>
                  <ul>
                    {selectedService?.map(service => (
                      <ServiceItemWrapper>
                        <ServiceName>{service.name}</ServiceName>
                        <div>x {service.quantity}</div>
                        <Button
                          variant="minimal"
                          onClick={() => {
                            updateOrder({
                              action: 'delete',
                              serviceId: service.service_id,
                              isWashService: service.is_wash,
                              orderId,
                            }).then(() => {
                              setSelectedService(selectedService
                                .filter(current => !((current.service_id === service.service_id)
                                  && (current.is_wash === service.is_wash))));
                            });
                          }}
                        // eslint-disable-next-line jsx-a11y/alt-text
                        ><img
                          src="https://www.pngall.com/wp-content/uploads/5/Delete-Bin-Trash-PNG-Clipart.png"
                          height="15px"
                          width="15px"
                        />
                        </Button>
                      </ServiceItemWrapper>
                    ))}
                  </ul>
                </td>
                <td>{services.total_sum}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      )}
    </>
  );
};

export default PreviewTable;


const ServiceName = styled.div`
color: ${({ color }) => color};
background-color: ${({ backgroundColor }) => backgroundColor};
border-radius: 8px;
width: 100px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  button{
    padding-right: 0 !important;
  }
`;

const SumPrice = styled.div`
  width: 50px;
`;

const ServiceItemWrapper = styled('li')`
  margin-top:3px;
  display:flex;
  flex-wrap:no-wrap;
  align-items:center;
  justify-content:space-between;
`;

const Price = styled.div`
  font-weight: 700;
  margin-top: 5px;
  display: flex;
justify-content: flex-end;
`;
