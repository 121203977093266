import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { SelectField } from '@/shared/components/form/Select';
import {
  FormButtonToolbar,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { marginRight } from '@/utils/directions';
import { WizardFormContainer, WizardTitle } from '@/shared/components/form/WizardFormElements';
import {
  Tabs as TabsWrapper, NavItem, NavLink, TabsWrap,
} from '@/shared/components/Tabs';
import { Nav, Tab } from 'react-bootstrap';
import Panel from '@/shared/components/Panel';
import Collapse from '@/shared/components/Collapse';
import { StepPropTypes, StepDefaultProps } from './Props';
import OrdersHistoryTable from '../OrdersHistoryTable';
import ServiceTabs from '../tabs/ServiceTabs';
import { FormField } from '../../../../../containers/Form/ReactHookForm/FormHookValidation/components/FormField';
import {
  getClientRecentOrders, getOrderPreview, getProfileOptions, getRadiusOptions, getTiresServices, getWashServicesByClass, updateOrder,
} from '../../../../../utils/api/task';
import WashServiceItem from '../tabs/WashServiceItem';
import TireServiceItem from '../tabs/TireServiceItem';

const StepThree = ({
  onSubmit, loyaltyDiscount, defaultValues, classId, clientId, orderId, phone, allSelectedServices, setAllSelectedServices, editMode = false, plateNumber,
}) => {
  const { handleSubmit, control } = useForm({ defaultValues });
  const [clientHistory, setClientHistory] = useState([
  ]);
  const [services, setServices] = useState([]);
  const [tiresServices, setTiresServices] = useState([]);
  const [radiusOptions, setRadiusOptions] = useState([]);
  const [profileOptions, setProfileOptions] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedRadius, setSelectedRadius] = useState(null);
  const [defaultServices, setDefaultServices] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [comment, setComment] = useState('');
  const [totalSum, setTotalSum] = useState(0);
  useEffect(() => {
    if (classId) {
      getWashServicesByClass(classId, orderId).then((res) => {
        getOrderPreview(orderId).then((response) => {
          setComment(response.data.comment || '');
          setDefaultServices(response.data.items);
          setServices(res.data.map((singleService) => {
            const findItem = response.data.items.find(item => item.service_id === singleService.service_id);
            if (findItem) {
              return { ...singleService, quantity: findItem.quantity };
            }
            return singleService;
          }));
          setTotalSum(response.data.total_sum);
        });
      });
      getRadiusOptions()
        .then(res => setRadiusOptions(res.data.map(item => ({ ...item, value: item.name, label: item.name }))));
      getProfileOptions()
        .then(res => setProfileOptions(res.data.map(item => ({ ...item, value: item.name, label: item.name }))));
    }
    // getClientRecentOrders(clientId).then(res => setClientHistory(res.data));
  }, [classId]);

  useEffect(() => {
    if (selectedProfile && selectedRadius) {
      getTiresServices(selectedRadius.radius_id, selectedProfile.profile_id, orderId).then(res => setTiresServices(res.data));
    }
  }, [selectedProfile, selectedRadius]);





  useEffect(() => {
    getClientRecentOrders(plateNumber).then(res => setClientHistory(res.data));
  }, []);





  useEffect(() => {
    if (defaultServices.length) {
      setAllSelectedServices(defaultServices.map(ser => ({
        id: ser.service_id, isWashService: true, quantity: ser.quantity, status: true, totalPrice: (ser.price || ser.price_item) * ser.quantity,
      })));
    }
  }, [defaultServices]);



  useEffect(() => {
    setTotalSum(allSelectedServices.reduce((acc, item) => acc + item.totalPrice, 0));
  }, [allSelectedServices, services]);



  const submit = async () => {
    allSelectedServices.forEach(async (item) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!Boolean(defaultServices.find(ser => ser.service_id === item.service_id || ser.service_id === item.id))) {
        if (item.isWashService) {
          await updateOrder({
            action: 'add',
            serviceId: item.id,
            isWashService: item.isWashService,
            orderId,
            phone,
            quantity: item.quantity,

          });
        } else {
          await updateOrder({
            action: 'add',
            serviceId: item.id,
            isWashService: item.isWashService,
            orderId,
            phone,
            quantity: item.quantity,
            tireProfileId: selectedProfile.profile_id,
            tireRadiusId: selectedRadius.radius_id,
          });
        }
      } else {
        await updateOrder({
          action: 'update',
          serviceId: item.id,
          isWashService: item.isWashService,
          orderId,
          phone,
          quantity: item.quantity,

        });
      }
    });
    if (editMode) {
      deletedItems.forEach(async (service) => {
        await updateOrder({
          action: 'delete',
          serviceId: service.id,
          isWashService: service.isWashService,
          orderId,
        });
      });
    }
    if (comment) {
      await updateOrder({
        comment,
        orderId,
      });
    }

    setTimeout(() => {
      onSubmit();
    }, 1000);
  };
  return (
    <Form horizontal>
      {clientHistory.length > 0 && !editMode && (
        <>

          <Collapse title="Последние заказы" defaultValue={false}>
            <OrdersHistoryTable
              history={clientHistory}
              allSelectedServices={allSelectedServices}
              setAllSelectedServices={setAllSelectedServices}
            />
          </Collapse>


        </>
      )}
      {/* {!editMode && (
        <CommentsBlock>
          <div>Комментарий к заказу</div>
          <input value={comment} onChange={e => setComment(e.target.value)} />
        </CommentsBlock>
      )} */}
      <FormButtonToolbar>
        <Button variant="special-red" type="button" className="previous" onClick={submit} disabled={!((allSelectedServices.length > 0))}>{editMode ? 'Подтвердить' : 'Подтвердить заказ'}</Button>
        {!!loyaltyDiscount && (
          <LoyaltyDiscount>
            Скидка {loyaltyDiscount}%
          </LoyaltyDiscount>
        )}
      </FormButtonToolbar>
      <FormGroup>
        <TabsWrapper>
          <Tab.Container defaultActiveKey="1">
            <TabsWrap>
              <Nav className="nav-tabs">
                <NavItem>
                  <NavLink eventKey="1">
                    Автомойка
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink eventKey="2">
                    Шиномонтаж
                  </NavLink>
                </NavItem> */}

              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <Services>
                    {services?.map(service => (
                      <WashServiceItem
                        service={service}
                        orderId={orderId}
                        phone={phone}
                        defaultValue={defaultServices.find(item => item.service_id === service.service_id) || allSelectedServices.find(current => ((current.id === service.service_id) || (current.service_id === service.service_id)))}
                        setServiceValues={(item) => {
                          if (item.status) {
                            const findedIndex = allSelectedServices.findIndex(current => (current.id === item.id)
                              && (current.isWashService === item.isWashService));
                            if (findedIndex !== -1) {
                              setAllSelectedServices(allSelectedServices.map((mappedService, index) => {
                                if (findedIndex === index) {
                                  return { ...item };
                                }
                                return mappedService;
                              }));
                            } else {
                              setAllSelectedServices([...allSelectedServices, item]);
                            }
                          } else {
                            setAllSelectedServices(allSelectedServices
                              .filter(selectedService => !((selectedService.id === item.id)
                                && (selectedService.isWashService === item.isWashService))));
                          }
                        }}
                        removeItem={() => {
                          if (editMode) {
                            setDeletedItems([...deletedItems, service]);
                          }
                        }}
                      />
                    ))}
                  </Services>
                </Tab.Pane>

              </Tab.Content>
            </TabsWrap>
          </Tab.Container>
        </TabsWrapper>
      </FormGroup>
      <FormGroup>
        {!editMode && <h5>Обшая сумма:  {totalSum} ₽</h5>}
      </FormGroup>
    </Form>
  );
};

StepThree.propTypes = StepPropTypes;
StepThree.defaultProps = StepDefaultProps;

export default StepThree;

// region STYLES


const FormGroupAddress = styled(FormGroup)`
  
  input:last-child {
    ${marginRight}: 15px;
    width: 70%;
  }
`;

// endregion

const Services = styled.div`
  display:grid
`;


const Form = styled(WizardFormContainer)`
padding:0 !important;
margin-top:25px
`;


const CommentsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 600;
`;

const LoyaltyDiscount = styled.div`
border-radius: 5px;
    padding: 10px 25px;
    margin-bottom: 20px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    margin-right: 15px;
    background-color: #ff4861;
    border: 1px solid;
    border-color: #ff4861;
    color: white;
`;
