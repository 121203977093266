/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '@/shared/components/Badge';
import { Button, Col, Row } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import BasicTableData from '../../../../containers/Tables/BasicTables/components/BasicTableData';

const { tableHeaderData, tableRowsData } = BasicTableData();

const OrdersHistoryTable = ({ history, allSelectedServices, setAllSelectedServices }) => {
  const { t } = useTranslation('common');
  const [isActive, setIsActive] = useState(false);
  return (
    <Col md={12} lg={12} xl={12} xs={12}>
      <div>
        <Table bordered responsive>
          <thead>
            <tr>
              {tableHeaderData.map(item => (
                <th key={item.id}>{item.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {history.map((item, index) => (
              <tr key={item.order_id}>
                <td>
                  <DateWrapper>

                    {item.datetime}
                    <Button
                      size="sm"
                      onClick={(e) => {
                        // const findedIndex = allSelectedServices.findIndex(current => (current.id === service.id)
                        //   && (current.isWashService === service.isWashService));
                        // if (findedIndex === -1) {
                        //   setAllSelectedServices([...allSelectedServices, service]);
                        //   setIsActive(true);
                        // } else {
                        //   alert('this service already included');
                        // }
                        const servicesFromOrder = item.items.filter(service => service.is_available).map(service => ({
                          id: service.service_id, isWashService: true, quantity: service.quantity, status: true, totalPrice: service.price_item * service.quantity,
                        }));
                        let mergedArray = [...allSelectedServices, ...servicesFromOrder];
                        console.log(mergedArray);
                        mergedArray = mergedArray.filter((arrayItem, pos) => mergedArray.map(a => a.id).indexOf(arrayItem.id) == pos);
                        setAllSelectedServices(mergedArray);
                      }}
                    > Повторить
                    </Button>
                  </DateWrapper>
                </td>
                <td>
                  <ul>
                    {item.items.map(service => (
                      <ServiceItemWrapper>
                        {service.name} x {service.quantity}
                      </ServiceItemWrapper>
                    ))}
                  </ul>
                </td>
                <td>{item.total_sum} / {item.total_sum_now}</td>

              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
};

export default OrdersHistoryTable;


const ServiceItemWrapper = styled('li')`
  margin-top:3px;
  display:flex;
  flex-wrap:no-wrap;
  align-items:center;
  justify-content:space-between
`;


const DateWrapper = styled.div`
  margin-top:15px
`;
