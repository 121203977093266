import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FirebaseIcon from 'mdi-react/FirebaseIcon';
import withAuthFirebase from '@/shared/components/account/auth/withAuthFirebase';
import LogInForm from '@/shared/components/account/loginForm/LogInForm';
import { login as loginAuth0 } from '@/shared/components/account/auth/withAuth0';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountOr,
  AccountSocial,
  AccountSocialButtonAuth0,
  AccountSocialButtonFirebase,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import { useDispatch } from 'react-redux';
import GoogleAuthBtn from '../AuthBtn/googleAuthBtn';
import FacebookAuthBtn from '../AuthBtn/fbAuthBtn';
import MetaMaskAuthBtn from '../AuthBtn/metaMaskAuthBtn';
import MicrosoftAuthBtn from '../AuthBtn/microsoftAuthBtn';
import { isAuth, login } from '../../../utils/api/auth';
import { auth } from '../../../redux/actions/authActions';
import { profile } from '../../../redux/actions/profileActions';

const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const LogIn = ({ changeIsOpenModalFireBase, history }) => {
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = async (user) => {
    const res = await login(user);
    if (res.result === 'ok') {
      localStorage.setItem('token', res.data.token);
      dispatch(auth({ name: res.data.name, role: res.data.role }));
      const userProfile = await isAuth();
      if (userProfile.email) {
        dispatch(profile(userProfile));
      }
      if (userProfile.role === 'master') {
        history.push('/master/tasks/new');
      } else {
        history.push('/cashier/tasks/active');
      }
    } else {
      setError(res.details);
    }
  };
  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>Добро пожаловать
              <AccountLogo> iSpaceWash
                <AccountLogoAccent>CRM</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            <h4 className="subhead">Моем с блеском</h4>
          </AccountHead>
          <LogInForm
            onSubmit={handleSubmit}
            form="modal_login"
            errorMessage={error}
          />
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
};

LogIn.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withAuthFirebase(LogIn);
