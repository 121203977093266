import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import LockScreen from '../../Account/LockScreen/index';
import LogIn from '../../Account/LogIn/index';
import LogInPhoto from '../../Account/LogInPhoto/index';
import Register from '../../Account/Register/index';
import RegisterPhoto from '../../Account/RegisterPhoto/index';
import ResetPassword from '../../Account/ResetPassword/index';
import ResetPasswordPhoto from '../../Account/ResetPasswordPhoto';
import WrappedRoutes from './WrappedRoutes';
import { isAuth } from '../../../utils/api/auth';
import PrivateRoute from './ProtectedRoute';
import { profile } from '../../../redux/actions/profileActions';

const Router = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const getUser = async () => {
    setLoading(true);
    const res = await isAuth();
    if (res.email) {
      dispatch(profile(res));
    }

    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <MainWrapper>
      <main>
        {!loading && (
          <Switch>
            <Route path="/404" component={NotFound404} />
            <Route path="/lock_screen" component={LockScreen} />
            <Route path="/log_in" component={LogIn} />
            <Route path="/log_in_photo" component={LogInPhoto} />
            <Route path="/register" component={Register} />
            <Route path="/register_photo" component={RegisterPhoto} />
            <Route path="/reset_password" component={ResetPassword} />
            <Route path="/reset_password_photo" component={ResetPasswordPhoto} />
            <PrivateRoute path="/">
              <WrappedRoutes />
            </PrivateRoute>
          </Switch>
        )}

      </main>
    </MainWrapper>
  );
};

export default Router;
