import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, ButtonToolbar } from '@/shared/components/Button';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import TopbarLanguage from '../components/topbar/TopbarLanguage';
import TopbarMail from '../components/topbar/TopbarMail';
import TopbarProfile from '../components/topbar/TopbarProfile';
import TopbarNotification from '../components/topbar/TopbarNotification';
import TopbarSearch from '../components/topbar/TopbarSearch';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';

import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarSearchWrap,
} from '../components/topbar/BasicTopbarComponents';
import TopbarMetaMask from '../components/topbar/TopbarMetaMask';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
}) => {
  const wallet = useSelector(state => state.wallet);
  const history = useHistory();
  const profile = useSelector(state => state.profile);
  return (
    <TopbarContainer>
      <TopbarLeft>
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
        <TopbarLogo to={profile.role === 'master' ? '/master/tasks/new' : '/cashier/tasks/active'} />
      </TopbarLeft>
      <TopbarRight>
        <TopbarRightOver>
          {(isMobile && profile.role === 'master') && (
            <>
              <CreateBtn variant="primary" onClick={() => { window.location.href = '/master/tasks/new'; }}>Новый заказ</CreateBtn>
              <CreateCircleBtn variant="danger" onClick={() => { window.location.href = '/master/tasks/new'; }}>+</CreateCircleBtn>
            </>
          )}
          <TopbarNotification />
          <TopbarProfile />
          {wallet && <TopbarMetaMask />}
        </TopbarRightOver>
      </TopbarRight>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;


const CreateBtn = styled(Button)`
margin-bottom:0px;
height: 40px;
align-self: center;
`;


const CreateCircleBtn = styled(Button)`
  margin-bottom:0px;
height: 40px;
align-self: center;
position: fixed;
z-index: 999999;
bottom: 10px;
right: 10px;
border-radius: 50%;
height: 50px;
width: 50px;
`;
