import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import { SelectField } from '@/shared/components/form/Select';
import {
  colorAccent, colorRed,
} from '@/utils/palette';
import { useAsyncDebounce } from 'react-table';
import {
  getCarInfoByPlate, getCarsInfo, getSuggestBrand, getSuggestModel, getSuggestName,
} from '../../../utils/api/task';
import { CreatableSelectField } from '../../../shared/components/form/CreatableSelect';

const CreateTask = ({ onSubmit, defaultValues, isValidCarNumber }) => {
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors, isValid },
    resetField,

  } = useForm({ shouldFocusError: false });
  const [modelsOptions, setModelsOptions] = useState([]);
  const [classesOptions, setClassesOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [modelsOptionsByBrand, setModelsOptionsByBrand] = useState([]);
  const [disableClassSelection, setDisableClassSelection] = useState(false);
  const [selectedModel, setSelectedModel] = useState('');
  const [suggestNames, setSuggestNames] = useState([]);
  const [suggestBrands, setSuggestBrands] = useState([]);
  const [suggestModels, setSuggestModels] = useState([]);
  async function getModels() {
    const res = await getCarsInfo();
    const response = { ...res, data: { ...res.data, models: { ...res.data.models[0] } } };

    if (modelsOptions.length === 0) {
      setModelsOptions(response.data.models);
    }
    setClassesOptions(response.data.classes.map(item => ({ ...item, value: item.id, label: item.name })));
    setBrandsOptions(response.data.brands.map(item => ({ ...item, value: item.id, label: item.name })));
    setSuggestBrands(response.data.brands.map(item => ({ ...item, value: item.id, label: item.name })));
    return response.data;
  }

  async function getSuggestNames(text) {
    const res = await getSuggestName(text);
    setSuggestNames(res.data.names.map((item, index) => ({ ...item, value: index, label: item })));
    return res.data;
  }


  const getSuggestBrands = useAsyncDebounce(async (text) => {
    const res = await getSuggestBrand(text);
    setSuggestBrands(res.data.brands.map((item, index) => ({ ...item, value: index, label: item.title })));
    return res.data;
  }, 200);


  async function getSuggestModels(text) {
    const selectedBrand = await getValues('brand_id');
    console.log(selectedBrand);
    const res = await getSuggestModel(text, selectedBrand.brand_id || selectedBrand.value);
    setSuggestModels(res?.data?.models?.map((item, index) => ({ ...item, value: index, label: item.name })) || []);
    return res.data;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!defaultValues.brand_id) {
      setModelsOptionsByBrand([]);
      setSelectedModel();
      reset();
      setModelsOptionsByBrand([]);
    }
    if (modelsOptionsByBrand.length === 0 && defaultValues.brand_id) {
      const { brands, classes, models } = await getModels();
      const selectedBrand = brands.map(item => ({ ...item, value: item.id, label: item.name })).find(item => item.id === defaultValues.brand_id);
      setValue('brand_id', selectedBrand);

      if (selectedBrand?.id) {
        setSuggestModels(models[selectedBrand.id]
          .map(item => ({ ...item, value: item.id, label: item.name })));
        setSelectedModel(models[selectedBrand.id].map(item => ({ ...item, value: item.id, label: item.name })));

        setValue('model_id', models[selectedBrand.id].map(item => ({ ...item, value: item.id, label: item.name }))
          .find(item => item.id === defaultValues.model_id));
        setValue('class_id', classes.map(item => ({ ...item, value: item.id, label: item.name })).find(item => item.id === defaultValues.class_id));
      }
    } else if (brandsOptions.length === 0) {
      await getModels();
    }
    if (defaultValues.phone_number) {
      setValue('phone_number', defaultValues.phone_number);
    }
    if (defaultValues.comment) {
      setValue('comment', defaultValues.comment);
    }
    if (defaultValues.name) {
      setValue('name', defaultValues.name);
    }
  }, [defaultValues]);
  const menuPortalTarget = document.getElementById('root');
  async function checkCarInfo(e) {
    await getCarInfoByPlate({ plate: e.target.value });
  }
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              {/* !!! */}
              <FormGroupLabel>Марка ТС</FormGroupLabel>
              <FormGroupField>
                <Controller
                  name="brand_id"
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      onChange={(e) => {
                        setValue('model_id', '');
                        setValue('class_id', '');
                        field.onChange(e);
                        if (modelsOptions?.[e?.value]) {
                          setSuggestModels(modelsOptions[e.value]
                            .map(item => ({ ...item, value: item.id, label: item.name })));
                        } else {
                          getSuggestModels('');
                        }
                      }}
                      maxMenuHeight={170}
                      onInputChange={(e) => {
                        if (e) {
                          getSuggestBrands(e);
                        }
                      }}
                      menuPlacement="auto"
                      menuPortalTarget={menuPortalTarget}
                      filterOption={() => true}
                      options={suggestBrands}
                    />
                  )}
                  rules={{ required: true }}
                  control={control}
                />
              </FormGroupField>
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>Модель ТС</FormGroupLabel>
              <FormGroupField>
                <Controller
                  name="model_id"
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      onChange={(e) => {
                        if (e.class_id) {
                          setValue('class_id', classesOptions.find(item => item.id === e.class_id));
                          setDisableClassSelection(true);
                          setSelectedModel(e);
                        }
                        field.onChange(e);
                      }}
                      maxMenuHeight={170}
                      menuPlacement="auto"
                      menuPortalTarget={menuPortalTarget}
                      onInputChange={(e) => {
                        if (e) {
                          getSuggestModels(e);
                        }
                      }}
                      filterOption={() => true}
                      options={suggestModels}
                    />
                  )}
                  defaultValue=""
                  rules={{ required: true }}
                  control={control}
                />
              </FormGroupField>
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>Класс ТС</FormGroupLabel>
              <FormGroupField>
                <Controller
                  name="class_id"
                  render={({ field }) => <SelectField {...field} options={classesOptions} isDisabled={!!defaultValues?.class_id || disableClassSelection} />}
                  defaultValue=""
                  control={control}
                  rules={{ required: true }}
                />
              </FormGroupField>
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>Телефон</FormGroupLabel>
              <FormGroupField>
                <Controller
                  name="phone_number"
                  control={control}
                  render={({ field }) => (
                    <CustomMaskedInput
                      mask={['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      name="carNumber"
                      type="text"
                      placeholder="+7 (999) 999-99-99"
                      minLength={16}
                      defaultValue={defaultValues.phone_number}
                      guide={false}
                      {...field}
                    />
                  )}
                  rules={{ required: false, minLength: 12 }}
                  defaultValue=""
                />
              </FormGroupField>
            </FormGroup>
            {!defaultValues?.client_company && (
              <FormGroup>
                <FormGroupLabel>Имя</FormGroupLabel>
                <FormGroupField>
                  <Controller
                    name="name"
                    render={({ field }) => (
                      <CreatableSelectField
                        {...field}
                        onInputChange={(e) => {
                          if (e === '') {
                            getSuggestNames('A');
                          } else {
                            getSuggestNames(e);
                          }
                        }}
                        maxMenuHeight={170}
                        menuPlacement="auto"
                        menuPortalTarget={menuPortalTarget}
                        formatCreateLabel={userInput => `Добавить ${userInput}`}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        placeholder={defaultValues?.name?.value || defaultValues?.name}
                        options={suggestNames}
                      />
                    )}
                    rules={{ required: false }}
                    control={control}
                    defaultValue=""
                  />
                  {/* !!! */}
                </FormGroupField>
              </FormGroup>
            )}
            {defaultValues?.client_company && <div>Обслуживание по договору для {defaultValues?.client_company}</div>}
            <FormButtonToolbar>
              <Button
                variant="primary"
                type="submit"
                disabled={errors.brand_id || errors.model_id || errors.class_id || errors.phone_number || errors.name || !isValidCarNumber || !selectedModel}
              >Дальше
              </Button>
            </FormButtonToolbar>
          </FormContainer>
        </CardBody>
      </Card>
    </Col>
  );
};

CreateTask.propTypes = {
  onSubmit: PropTypes.func,
};

CreateTask.defaultProps = {
  onSubmit: () => { },
};

export default CreateTask;


const CheckboxWrapper = styled.div`
 
  margin-top:10px
`;

const CustomMaskedInput = styled(MaskedInput)`
:active{

  border-color: ${({ error }) => (error ? colorRed : colorAccent)} !important;
}
:focus{

border-color: ${({ error }) => (error ? colorRed : colorAccent)} !important;
}
`;
